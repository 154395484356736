<template>
    <div class="body-cont">
          <div class="div-container app-header">
              <div class="div-row">
                  <div class="div-col12">
                    <NavTopbar :user="user"/>
                  </div>
              </div>
          </div>
        <div class="app-body-container appbody-content">
			<div class="app-body-row">
				<div class="app-body-col">
                    <div class="vertical-center">
                        <input type="radio" name="dot" id="one">
                        <input type="radio" name="dot" id="two">
                        <div class="main-card">
                        <div class="cards">
                            <div class="card">
                            <div class="content">
                            <div class="img">
                                <img  :src="selectedUser.avatar_full_path" @click="avatarClickHandler()" alt="">
                            </div>
                            <div class="details">
                                <div class="name" v-text="selectedUser.name"></div>
                                <div class="job"></div>
                            </div>
                            <div class="media-icons" style="display:none">
                                <a href="#"><i class="fab fa-facebook-f"></i></a>
                                <a href="#"><i class="fab fa-twitter"></i></a>
                                <a href="#"><i class="fab fa-instagram"></i></a>
                                <a href="#"><i class="fab fa-youtube"></i></a>
                            </div>
                            </div>
                            </div>

                        </div>
                        </div>
                        <div class="button">
                        <label for="one" class=" active one"></label>
                        <label for="two" class="two"></label>
                        </div>
                    </div>
                    <input
                        hidden
                        type="file"
                        ref="avatar-uploader"
                        @change="onFileChange($event, 'avatar')"
                    />
                    <input
                        hidden
                        type="file"
                        ref="image-uploader"
                        @change="onFileChange($event, 'image')"
                    />

                    <input type="hidden"
                                class="user-name"
                                color="black"
                                v-model="selectedUser.name"
                                @blur="updateUserName()"
                                :disabled="!isAuthUser"
                                :rules="[() => !!selectedUser.name || '']"
                                height="50px"
                                outlined
                                hide-details
                                v-bind="attrs"
                                v-on="on"
                            />


                </div>
            </div>
        </div>
    </div>























</template>

<script>
import api from "@/api";
import _ from "lodash";
import questionOptions from "@/assets/question-options.json";
import questionOptionIcons from "@/assets/question-option-icons.json";
import { mapMutations } from "vuex";
import SearchBar from "@/components/SearchBar";
import NavTopbar from "@/components/NavTopbar";

export default {
    props: ["user"],
    components: {
        "search-bar": SearchBar,
        "NavTopbar":NavTopbar,

    },
    data: () => ({
        active:false,
        selectedUser: "",
        form: {},
        actionButtons: {
            sendRequest: {
                name: "sendRequest",
                displayName: "Invite",
                src: "/images/user-action-buttons/invite",
                disabled: false,
                togglable: true
            },
            sendMessage: {
                name: "sendMessage",
                displayName: "Message",
                src: "/images/user-action-buttons/message",
                disabled: false,
                togglable: false
            },
            like: {
                name: "like",
                displayName: "Like",
                src: "/images/user-action-buttons/like",
                disabled: false,
                togglable: true
            },
            review: {
                name: "review",
                displayName: "Review",
                src: "/images/user-action-buttons/review",
                disabled: false,
                togglable: true
            }
        },
        questionOptions: questionOptions,
        questionOptionIcons: questionOptionIcons,
        profiles: [],
        snackbar: {
            show: false,
            text: ""
        },
        passedData: "",
        isLoading: {
            user: false,
            upload: false,
            submitReview: false,
            uploadImage: false
        },
        showSearchBar: false,
        dialog: false,
        searchKeyword: "",
        states: [
            "Alabama",
            "Alaska",
            "American Samoa",
            "Arizona",
            "Arkansas",
            "California",
            "Colorado",
            "Connecticut",
            "Delaware",
            "District of Columbia",
            "Federated States of Micronesia",
            "Florida",
            "Georgia",
            "Guam",
            "Hawaii",
            "Idaho",
            "Illinois",
            "Indiana",
            "Iowa",
            "Kansas",
            "Kentucky",
            "Louisiana",
            "Maine",
            "Marshall Islands",
            "Maryland",
            "Massachusetts",
            "Michigan",
            "Minnesota",
            "Mississippi",
            "Missouri",
            "Montana",
            "Nebraska",
            "Nevada",
            "New Hampshire",
            "New Jersey",
            "New Mexico",
            "New York",
            "North Carolina",
            "North Dakota",
            "Northern Mariana Islands",
            "Ohio",
            "Oklahoma",
            "Oregon",
            "Palau",
            "Pennsylvania",
            "Puerto Rico",
            "Rhode Island",
            "South Carolina",
            "South Dakota",
            "Tennessee",
            "Texas",
            "Utah",
            "Vermont",
            "Virgin Island",
            "Virginia",
            "Washington",
            "West Virginia",
            "Wisconsin",
            "Wyoming"
        ],
        e6: "",
        showReviewDialog: false,
        reviewCategories: [],
        currentReview:50
    }),

    computed: {
        isAuthUser() {
            return this.user.id === this.selectedUser.id;
        },
        isFriendOfAuthUser() {
            return !!(
                this.selectedUser &&
                this.selectedUser.friend_ids.includes(this.user.id)
            );
        },
        ifAuthUserRequested() {
            return !!(
                this.selectedUser &&
                this.selectedUser.all_friend_ids.includes(this.user.id)
            );
        },
        disableSendRequest() {
            return !!(this.isAuthUser || this.ifAuthUserRequested);
        },
        computedActionButtons() {
            const actionButtons = this.actionButtons;
            actionButtons["sendRequest"].disabled = this.disableSendRequest;
            actionButtons["sendMessage"].disabled = this.isAuthUser;
            return actionButtons;
        },
        isLikedByAuthUser() {
            if (!this.selectedUser || !this.selectedUser.liked_by.length) {
                return;
            }
            const userIds = _.map(this.selectedUser.liked_by, "user_one_id");
            return !!userIds.includes(this.user.id);
        },

        computedContacts() {
            if (!this.selectedUser || !this.selectedUser.friends) {
                return;
            }
            return this.selectedUser.friends.filter(friend => {
                return friend.name
                    .toLowerCase()
                    .includes(this.searchKeyword.toLowerCase());
            });
        },

        sortedProfiles() {
            for (const key in this.profiles) {
                if (key === "relations") {
                    let obj = {};
                    obj[key] = this.profiles[key];
                    delete this.profiles[key];
                    this.profiles = Object.assign(obj, this.profiles);
                }
            }
            return this.profiles;
        },

        reviewScoresByAuthUser() {
            if (!this.selectedUser || !this.selectedUser.reviews) {
                return [];
            }
            return this.selectedUser.reviews.review_scores_by_auth_user;
        },
        items() {
            const items = [
                { name: "userMenu", text: this.user.name, icon: "fa-solid fa-user" },
                {
                    name: "notifications",
                    text: "Notifications",
                    icon: "fa-solid fa-bell"
                },
                { name: "contacts", text: "Contacts", icon: "fa-regular fa-address-book" },
               /* {
                    name: "people",
                    text: "People",
                    icon: "fa-solid fa-users"
                },*/

               // { name: "settings", text: "Settings", icon: "fa-solid fa-gear" },
                { name: "logout", text: "Logout", icon: "fa-sharp fa-solid fa-right-from-bracket" }
            ];
            return items;
        },


    },

    mounted() {
        this.getProfiles();
        this.getUser();
        this.getReviewCategories();
    },

    watch: {},

    methods: {
        ...mapMutations("common", ["updateState"]),
        async getUser(showLoading = true) {
            if (showLoading) {
                this.isLoading.user = true;
            }
            const res = await api.user.show(this.$route.params.id);
            this.selectedUser = res.data;
            this.updateState({ selectedUser: this.selectedUser });
            this.updateUserRelatedData();
            this.isLoading.user = false;
        },

        updateUserRelatedData() {
            if (!_.get(this.selectedUser, "liked_by.length")) {
                return;
            }
            const userIds = _.map(this.selectedUser.liked_by, "user_one_id");
            this.actionButtons["like"].disabled =
                this.isAuthUser || !!userIds.includes(this.user.id);
        },

        async getProfiles() {
            const res = await api.profile.getAll();
            this.profiles = res.data;
        },

        setReview(value){
            this.currentReview=value;
        },

        async updateProfile(profile) {
            try {
                if (!this.isAuthUser) {
                    return;
                }
                await api.user.updateProfile(this.user.id, profile);
                this.snackbar.text = "Successfully Saved";
                this.snackbar.show = true;
            } catch (err) {
                console.log("error is ", err);
            }
        },

        onFileChange(e, fileType) {
            const propName =
                fileType === "avatar" ? "avatar_full_path" : "image_full_path";
            this.$set(this.selectedUser, propName, "");
            let files = e.target.files || e.dataTransfer.files;
            this.passedData = files[0].name;
            if (!files.length) return;
            this.createImage(files[0], fileType);
        },

        createImage(file, fileType) {
            this.isLoading.upload = true;
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = e => {
                let src = e.target.result;
                if (fileType === "avatar") {
                    this.onLoad({
                        src,
                        file
                    });
                } else if (fileType === "image") {
                    this.onLoadForImage({
                        src,
                        file
                    });
                }
            };
        },

        async onLoad(data) {
            try {
                let formData = new FormData();
                formData.append("avatar", data.file);
                this.selectedUser.avatar_full_path = "";
                const res = await api.user.uploadAvatar(this.user.id, formData);
                this.selectedUser.avatar_full_path = res.data.avatar_full_path;
                this.isLoading.upload = false;
            } catch (err) {
                console.log(err.message);
            }
        },

        async onLoadForImage(data) {
            try {
                this.isLoading.uploadImage = true;
                let formData = new FormData();
                formData.append("image", data.file);
                const res = await api.user.uploadImage(this.user.id, formData);
                this.isLoading.upload = false;
                this.getUser(false);
                this.isLoading.uploadImage = false;
            } catch (err) {
                console.log(err.message);
            }
        },

        async updateUserDescription() {
            this.updateUserData({
                description: this.selectedUser.description
            });
        },

        async updateUserName() {
            if (!this.selectedUser.name) {
                return;
            }
            this.updateUserData({
                name: this.selectedUser.name
            });
        },

        async updateUserData(data) {
            if (!this.isAuthUser) {
                return;
            }
            await api.user.update(this.user.id, data);
            this.snackbar.text = "Successfully Saved";
            this.snackbar.show = true;
        },

        actionButtonHandler(button) {
            if ((button.disabled && !button.togglable) || this.isAuthUser) {
                return;
            }
            switch (button.name) {
                case "sendRequest":
                    button.disabled ? this.cancelRequest() : this.sendRequest();
                    button.disabled = !button.disabled;
                    break;
                case "sendMessage":
                    this.openChatbox();
                    break;
                case "like":
                    button.disabled ? this.dislike() : this.like();
                    button.disabled = !button.disabled;
                    break;
                case "review":
                    this.openReviewDialog();
                    break;
            }
        },

        async sendRequest() {
            await api.user.sendRequest(this.user.id, this.selectedUser.id);
            this.snackbar.text = "Friend Request has been successfully sent";
            this.snackbar.show = true;
        },

        async cancelRequest() {
            if (this.isFriendOfAuthUser) {
                return;
            }
            await api.user.cancelRequest(this.user.id, this.selectedUser.id);
            this.snackbar.text =
                "Friend Request has been successfully cancelled";
            this.snackbar.show = true;
        },

        async openChatbox() {
            this.$emit("open-chatbox", this.selectedUser);
        },

        async like() {
            await api.user.like(this.user.id, this.selectedUser.id);
        },

        async dislike() {
            await api.user.dislike(this.user.id, this.selectedUser.id);
        },
        openReviewDialog() {
            this.showReviewDialog = true;
        },

        async submitReview(reviewScores) {
            this.isLoading.submitReview = true;
            const obj = {
                reviews: reviewScores,
                currentReview:this.currentReview
            };
            await api.user.submitReview(this.selectedUser.id, obj);
            this.isLoading.submitReview = false;
            this.showReviewDialog = false;
        },

        goToUserPage(user) {
            this.$router.push({
                name: "User",
                params: { id: user.id }
            });
        },

        avatarClickHandler() {
            if (!this.isAuthUser) {
                return;
            }
            this.$refs["avatar-uploader"].click();
        },

        imageClickHandler() {
            if (!this.isAuthUser) {
                return;
            }
            this.$refs["image-uploader"].click();
        },

        actionButtonSrc(button) {
            return button.disabled
                ? `${button.src}_disabled.svg`
                : `${button.src}.svg`;
        },

        updateUriByKeyword(keyword = "") {
            this.searchKeyword = keyword;
        },
        typeOfNaN(data) {
            return Number.isNaN(data) ? 0 : data;
        },
        async getReviewCategories() {
            const res = await api.review.all();
            this.reviewCategories = res.data;

            for (let category of this.reviewCategories) {
                if (!category.pivot) {
                    _.set(category, "pivot.value", 0);
                }
            }
            if (
                _.get(this.selectedUser, "reviews.review_scores_by_auth_user")
            ) {
                this.reviewCategories = _.merge(
                    _.keyBy(this.reviewCategories, "name"),
                    _.keyBy(
                        this.selectedUser.reviews.review_scores_by_auth_user,
                        "name"
                    )
                );
            }
        },

        async deleteImage(image) {
            await api.user.deleteImage(this.selectedUser.id, {
                data: {
                    image_id: image.id
                }
            });
            this.getUser();
        },

        getReviewImageSrc(index, score) {
            const to = 20 * index;
            return to < score
                ? "/images/review_sheild_filled.png"
                : "/images/review_sheild.png";
        },
        async toggleNotifications() {
            this.showAddressBook = false;
            this.showNotifications = true;
            this.active=false;
            await api.notification.markAsRead();
          // this.getUser();
        },
        CloseNotification(){
        this.showNotifications= false;
        this.active=true;
        },
        toggleAddressBooksclose(){
        this.showAddressBook= false;
        this.active=true;
        },
        async toggleAddressBooks() {
            this.showNotifications = false;
            this.showAddressBook = true;
            this.active= false;
        },
        showListview(){
          this.showlist= !this.showlist;
        },

        menuActionHandler(item) {
            switch (item.name) {
                case "userMenu":
                    location.href = `/user/${this.user.id}`;
                    break;
                case "notifications":
                    this.toggleNotifications();
                    break;
                case "contacts":
                    this.toggleAddressBooks();
                    break;
                //case "people":
                //    this.goToHome();
                //    break;
              /**   case "randomChat":
                    location.href = "/video-chat";
                    break;
                case "settings":
                    location.href = "/settings";
                    break;
                case "liveVideoId":
                    this.openLiveVideoIdDialog();
                    break;
                    */
                case "logout":
                    this.logout();
                    break;
            }
        },
        mouseOverFun(){
            if(this.active){
            this.active =false;
            }else{
              this.active=true;
            }
        },
        mouseOverout: function(){
              this.active =false;
        },
        goToHome() {
        location.href = "/";
        },
        goToDashboard() {
        location.href = "/dashboard";
        },
        logoSrc() {
            return "/images/logo/net-app-log-white.png";
        },
        logoVideoSrc() {
                return "/images/logo/video-camera-white.png";
        },
        goToNewStreamingPage(){
          location.href = "/new-streaming";
        },

        async logout() {
          const userauth = localStorage.getItem("userId");

            try {
                await api.user.updateToOffline(userauth);
            } finally {
                if (this.user.id) {
                    await api.auth.logout();
                }
                localStorage.removeItem("userId");
                localStorage.removeItem("isLoggedIn");
                location.href = "/login";
                this.$router.push({ name: "Login" });
            }
        },
    }
};
</script>

<style lang="scss" scoped>
.top-bar {
    height: 350px;
}
.bg {
    height: 200px;
    background-color: var(--v-primary-base);
    position: absolute;
    left: 0;
    right: 0;
}
.action-btns {
    top: 65%;
    .action-btn {
        width: 60px;
        &:not(.disabled):not(.no-pointer) {
            cursor: pointer;
            &:hover {
                opacity: 0.5;
            }
        }
    }
}
.info-card {
    border-top: 10px solid var(--v-primary-base) !important;
}
.v-text-field.no-border > .v-input__control > .v-input__slot:before {
    border-style: none;
}
.friend-wrapper {
    .name {
        background-color: rgb(0 0 0 / 65%) !important;
        color: white;
        position: absolute;
        bottom: 0;
        margin: 0;
        width: 100%;
    }
}
.left-column {
    height: 100%;
    top: 55px;
}
</style>

<style lang="scss">
.user-name {
    @media screen and (min-width: 600px) {
        top: 40%;
    }
    input {
        @media screen and (min-width: 600px) {
            color: white !important;
        }
        @media screen and (max-width: 599px) {
            color: black !important;
            text-align: center;
        }
        text-overflow: ellipsis;
        font-size: 40px !important;
        max-height: 50px;
    }
    &:not(.v-input--is-focused) fieldset {
        border-width: 0;
    }
}
.image-uploader {
    border: 1px solid #eee;
    height: 100px;
}
.clear-image-btn {
    background-color: #f44336;
    border-radius: 50%;
    color: white !important;
    font-size: 14px;
    position: absolute !important;
    top: 0;
    right: 0;
}
.images-wrapper {
    height: 320px;
}
</style>

<style lang="scss">
.review-input input {
    padding: 0;
}

        * {box-sizing: border-box;
          padding: 0;
        margin: 0;
      }


    .div-container.app-header {
        background: #2e2e2e;
        height: auto;
        padding: 5px;
        box-shadow: 0px 6px 8px -5px rgba(0,0,164,0.29);
      }
      .div-col12 {
  width: 100%;
}
.app-body-container.appbody-content {
  padding: 15px 0;
}
.div-row {
  flex-wrap: wrap;
  display: flex;
}
      .body-cont {
  max-width: 700px;
  margin: 0 auto;
}
.logo-sec {
  width: 60px;
  float: left;
}
.app-logo {
  width: 100%;
}

ul{
  padding: 0;
  margin: 0;

}
.headlogo-li{
  list-style: none;
    float: left;
    width: 33.3%;
}
.video-icon-sec {
  width: 50px;
margin: 5px auto;
}
.profile-img-sec {
  width: 60px;
  border-radius: 100%;
  float: right;
  text-align: center;
  height: 60px;
  padding-top: 6px;
}
.net-profile-img {
  width: 50px;
  border-radius: 50%;
  height: 50px;
}


.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: block;
  position: absolute;
  background-color: #fff;
  min-width: 50px;
  box-shadow: 0px 6px 12px 0px rgba(0,0,164,0.29);
  z-index: 1;
  top: 70px;
  right: 0;
}

.dropdown-content a {
  color: #3C4043;;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  font-size: 16px;
}

.dropdown-content a:hover {background-color: #ddd; color:#0000A4;}

.dropdown:hover .dropdown-content {display: block;}

.profile-menu i{
  font-size: 16px;
  margin-right: 12px;
}
.app-profl-sec{
    position: relative;
  background: #BBB6B6;
  border-radius: 20px;
  box-shadow: 0px 0px 14px -6px rgba(0,0,164,1);
}


.app-body-contli {
width: 44%;
list-style: none;
margin: 10px;
display: inline-block;
}
.profile-cont-img {
  width: 100%;
  max-height: 200px;
  object-fit: cover;
  min-height: 200px;
  border-radius: 20px;

}
.text-block {
  position: absolute;
  bottom: 0;
  background: rgb(255, 255, 255);
  background: rgba(255, 255, 255, 0.9);
  color: #f1f1f1;
  width: 100%;
  transition: .5s ease;
  opacity: 1;
  color: #3C4043;
  font-size: 20px;
  padding: 10px;
  text-align: center;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
}
.profname {
  margin: 0;
  padding: 0;
  width: 100%;
  text-align: left;
}
.app-body-ul{
  margin: 0 auto;
}
.profname span{
  width:33.3%;
  float: left;
  font-size: 16px;
  padding: 0;
margin: 0;
}
.profclock-span {
  text-align: center;
    padding: 0;
margin: 0;
}
.profstar-span {
  text-align: right;
  padding: 0;
margin: 0;
}
.profstar-span:hover {
  color: #E4AC1A;
}
/*.search-sec {

  border-radius: 100px;
  border: 1px solid #A6A6A6;

  padding: 5px;
}*/
.search-sec input{
  border: none;
  width: 75%;
  border-radius: 100px;
  padding: 10px 15px;
}
.app-bottom-col {
  display: flex;
}

.filt-view {
  text-align: right;
  text-align: center;
}
.thumbview-span {
  width: 50%;
display: inline-block;
text-align: center;
padding: 0 8px;
border-top-left-radius: 30px;
border-bottom-left-radius: 30px;
}
.mapview-span{
  width: 50%;
display: inline-block;
text-align: center;
padding: 12px 8px;
border-top-right-radius: 30px;
border-bottom-right-radius: 30px;


}
.filt-view {
  padding: 0;
  margin: 0;
border: 1px solid #ced4da;
border-radius: 30px;

}
.filt-view button{
  background: #fff;
  border: none;
}
.app-bottom-container.app-bottom {
  background: #fff;
  width: 100%;
height: auto;
padding: 10px;
display: flex;
position: fixed;
bottom: 0;
left: 0;
right: 0;
z-index: 100;
  box-shadow: 0px -5px 11px -8px rgba(0,0,164,0.75);
}
.appbody-profcont{
  width: 100%;
}
.appbody-profcont tr td{
  padding: 10px;
}
.prof-namcont tr td{
  padding: 10px 5px;
  width: 33%;
}
.prof-namcont{
  width: 100%;
}
.botbar-table{
  width: 100%;
}
.botbar-table tr td:nth-child(1){
  width: 65%;
}


.input-group {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
}
*, ::after, ::before {
  box-sizing: border-box;
}

.input-group > .custom-select:not(:last-child), .input-group > .form-control:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.form-control.input-form {
  padding: 15px 15px;
}
.input-group > .custom-file, .input-group > .custom-select, .input-group > .form-control, .input-group > .form-control-plaintext {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
button, input {
  overflow: visible;
}
button, input, optgroup, select, textarea {
  margin: 0;
    margin-bottom: 0px;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
.input-group-append {
  margin-left: -1px;
}
.input-group-append, .input-group-prepend {
  display: -ms-flexbox;
  display: flex;
}
.input-group > .input-group-append > .btn, .input-group > .input-group-append > .input-group-text, .input-group > .input-group-prepend:first-child > .btn:not(:first-child), .input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child), .input-group > .input-group-prepend:not(:first-child) > .btn, .input-group > .input-group-prepend:not(:first-child) > .input-group-text {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-icon {
  background: transparent;
  color: #888;
  font-size: 24px;
}
.input-group-text {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: .375rem .75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: .25rem;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}
.app-bottom-row {
  width: 100%;
}

table {
  border-collapse: collapse;
}
.form-control:focus-visible{
  border: none;
  outline: none;
  box-shadow: 0;
}

.search-sec .input-group {
  border: 1px solid #ced4da;
  border-radius: 50px;
}
.input-group-text.input-icon {
  border: none;
  background: transparent;
}



.mainprof-sec .input-group {
  border: 2px solid #ced4da;


}
.mainprof-input{
  margin-top:15px ;
}

.cover-photosec {
  display: block;
  width: 100%;
  height: 350px;
  border: 2px solid #ced4da;

  margin-top: 15px;

}
.mainprof-botbar-table {
  width: 100%;
}
.bot-livbtn {
width: 60px;
display: block;
border: none;
border-radius: 100%;
height: 60px;

margin: 0 auto;
text-align: center;
box-shadow: 0px 0px 16px -8px rgba(0,0,164,0.75);
color: #949497;
font-weight: 500;
}
.bot-livbtn .strambt-icon {
  width: 100%;
}
.mainprof-botbar-table tr td {
  width: 20%;
  text-align: center;
}
.bot-icon i{
  font-size: 18px;
  color: #949497;
}
.bot-icon {
  display: block;
  width: 45px;
  margin: 0 auto;
  height: 45px;
  border-radius: 100%;
  line-height: 30px;
  background: #DFDFDF;
}
.bot-icon img{
  width: 100%;
}
.bot-icon.active{
  background: #0000A4;

}
.bot-icon.active i{
  color: #fff !important;
}

.mainprof-sec .input-group {
  border: 2px solid #ced4da;
}
.form-control.input-form {
  border: none;
}
.mart15{
  margin-top: 15px;
}
.app-body-container.cratstrbody-content{
  padding: 15px;
}
.input-group.mainprof-input {
  border: 2px solid #ced4da;
}





.socialicon-listul{
  list-style: none;
}
.socialicon-list {
  max-width: 50px;
  position: absolute;
  right: 30px;
  bottom: 70px;
  display: none;
}
.socialicon-list li a {
  display: block;
  padding:10px;
  font-size: 18px;
  border-radius: 100%;
  background: #0000a4;
  color: #fff;
  margin: 5px 0;
}
.socialicon-list {
  max-width: 50px;
  position: absolute;
  right: 30px;
  bottom: 70px;
}


#share-button:focus + .socialicon-list{
  display:block !important;
}







.sidber-notification {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top:70px;
  right: 0;
  background-color: #fff;
  overflow-x: hidden;
  transition: 0.5s;
  box-shadow: -4px 0px 5px 0px rgba(0,0,164,0.29);
}

.sidber-notification a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 18px;
  color: blue;
  display: block;
  transition: 0.3s;
}

.sidber-notification a:hover {
  color: #f1f1f1;
}

.sidber-notification .closebtn {
  font-size: 20px;
  background: #2e2e2e;
  box-shadow: 0px 6px 8px -5px rgba(0,0,164,0.29);
  color: #fff;
}
.sidber-notification .closebtn:hover {
  font-size: 20px;
  background: #2e2e2e;
}
.sidber-notification .closebtn span {
 font-size: 40px;
  float: right;
  line-height: 20px;

}

@media screen and (max-height: 450px) {
  .sidber-notification {padding-top: 15px;}
  .sidber-notification a {font-size: 18px;}
}



.mostrecnt_contac {
  height: 40%;
}

.contac_sec {
  height: 40%;
}
.contct_heding {
  font-size: 20px;
  padding: 8px 32px;
  background: #2e2e2e;
  color: #fff;
}






.app-body-container.livebody-content {
  height: 90vh;
}
.live-img {
  width: 100%;
  height: 100%;
  background-size: cover;
  height: 100vh;
}
.livestream-body{
  height: 90vh;
  position: relative;
  z-index: 0;
}
.livestram-boticon {
  width: 100%;
  height: auto;
  background: rgb(0,0,0);
background: linear-gradient(0deg, rgba(0,0,0,0.7775442940848214) 81%, rgba(255,255,255,0.5030344901632529) 100%);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;

}

.vierslist-tavle {
  width: 100%;
}
.vierslist-tavle .total-viersnum {
  text-align: right;
  color: #fff;
}
.vierslist-tavle .viewrs-titletxt{
  font-size: 16px;
   color: #fff;
}
.vierslist-tavle .viewrs-titletxt span{
  font-size: 16px;
  margin-right: 10px;
   color: #fff;
}
.viewers-sec{
  padding: 10px;
  background: #000;
}
.liveacc-imgicon {
  padding: 10px;
}
.viersname{
  color: #fff;
  font-size: 14px;
  padding: 7px 0;
  border-bottom: 1px solid #303841;
}
.viewrs-list {
  padding: 16px 0 5px 0;
}

.viewrs-list{
  display: block;
}


.viewrs-numsec{
  cursor: pointer;
}




.live-bot-icon {
  display: block;
  width: 30px;
  margin: 0 auto;
  height: 30px;
  border-radius: 100%;
  line-height: 30px;
}
.liveacc-botbar-table{
  width: 100%;
}
.liveacc-botbar-table tr td {
  width: 13%;
  text-align: center;
}
.live-bot-icon img{
  width: 100%;
}
.live-bot-livbtn {
  border: none;
  background: transparent;
}
.viewer{
    width: 170px;
    float: right;
    position: relative;
    z-index: 1;
}
.other-user-videoess{
  width: 100vh;
  position: absolute;
  height: 100vh;
}
.div-col12 {
    width: 100%;
    z-index: 999999999999999999;
}
.streem {
    width: auto;
    position: fixed;
    min-width: 100%;
    min-height: 100%;
    right: 0;
    height: 100vh;
    top: 73%;
    left: 63%;
    transform: translate(-51%,-68%);
}
.remoteviewer{
  width: auto;
    position: fixed;
    min-width: 100%;
    min-height: 100%;
    right: 0;
    height: 100vh;
    top: 73%;
    left: 63%;
    transform: translate(-51%,-68%);
}
video[poster]{ object-fit:none; }  /* or object-fit:fill */

/**.container{
  max-width: 950px;
  width: 100%;
  overflow: hidden;
  padding: 80px 0;
} **/
.main-card{
    margin-top: -15px;
}
.container .main-card{
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  transition: 1s;
}
#two:checked ~ .main-card{
  margin-left: -100%;
}
.container .main-card .cards{
 /* width: calc(100% / 2 - 10px); */
  display: flex;
  flex-wrap: wrap;
  margin: 0 20px;
  justify-content: space-between;
}
.main-card .cards .card{
  width: calc(100% / 3 - 10px);
  background: #fff;
  border-radius: 0px;
  padding: 30px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);
  transition: all 0.4s ease;
}
.main-card .cards .card:hover{
  transform: translateY(-15px);
}
.cards .card .content{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.cards .card .content .img{
  height: 130px;
  width: 130px;
  border-radius: 50%;
  padding: 3px;
  background: transparent;
  margin-bottom: 14px;
}
.card .content .img img{
  height: 100%;
  width: 100%;
  border: 3px solid #ffff;
  border-radius: 50%;
  object-fit: cover;
}
.card .content .name{
  font-size: 20px;
  font-weight: 500;
}
.card .content .job{
  font-size: 20px;
  color: #FF676D;
}
.card .content .media-icons{
  margin-top: 10px;
  display: flex;
}
.media-icons a{
  text-align: center;
  line-height: 33px;
  height: 35px;
  width: 35px;
  margin: 0 4px;
  font-size: 14px;
  color: #FFF;
  border-radius: 50%;
  border: 2px solid transparent;
  background: #FF676D;
  transition: all 0.3s ease;
}
.media-icons a:hover{
  color: #FF676D;
  background-color: #fff;
  border-color: #FF676D;
}
 .container .button{
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 20px;
}
.button label{
  height: 15px;
  width: 15px;
  border-radius: 20px;
  background: #fff;
  margin: 0 4px;
  cursor: pointer;
  transition: all 0.5s ease;
}
.button label.active{
  width: 35px;
}
#one:checked ~ .button .one{
  width: 35px;
}
#one:checked ~ .button .two{
  width: 15px;
}
#two:checked ~ .button .one{
  width: 15px;
}
#two:checked ~ .button .two{
  width: 35px;
}
input[type="radio"]{
  display: none;
}
@media (max-width: 768px) {
  .main-card .cards .card{
    /*margin: 20px 0 10px 0;
    width: calc(100% / 2 - 10px); */
  }
}
@media (max-width: 600px) {
  .main-card .cards .card{
    /* margin: 20px 0 10px 0; */
    width: 100%;
  }
}
.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  width:100%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
</style>
