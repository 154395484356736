<template>

    <div
      class="search-bar-wrapper rounded-pill"
      :class="{ expand: showSearchBar }"
      :style="{ width: searchBarWidth }"
    >
      <input style="width: 96%;"
        class="search-bar rounded-pill"
        append-icon="mdi-magnify"
        dense
        rounded
        hide-details
        placeholder="Search"
        @input="updateUriByKeyword"
      />


    </div>

</template>

<script>
export default {
  props: {
    width: {
      type: [String, Number],
      default: "100%"
    },
    btnWidth: {
      type: [String, Number],
      default: ""
    },
    btnHeight: {
      type: [String, Number],
      default: ""
    },
    isSearchBarClicked: {
      type: Boolean,
      deafult: false
    },
    isPersistentSearchBar: {
      type: Boolean,
      deafult: false
    }
  },
  data() {
    return {
      showSearchBar: this.isPersistentSearchBar,
      searchKeyword: ""
    };
  },

  computed: {
    searchBarWidth() {
      return this.showSearchBar ? this.width : 0;
    }
  },

  watch: {
    isSearchBarClicked() {
      this.showSearchBar = !this.showSearchBar;
    }
  },

  methods: {
    updateUriByKeyword(keyword) {
      this.$emit("update-keyword", keyword);
    }
  }
};
</script>

<style lang="scss" scoped>
.search-bar-wrapper {
  transition: width 0.5s;
  height: 30px;
}
.search-icon {
  margin-right: 12px;
}
.search-bar{
  border: 2px solid;
  border-color: #b8aeae52;
  height: 40px;
  margin-top: 9px;
  margin-left:10px;
  margin-right: 10px;
  border-radius: 20px !important;
}


</style>
